import PropTypes from 'prop-types';
import { twJoin } from 'tailwind-merge';

import Icon from 'shopper/components/Icon';
import Text from 'shopper/components/Text';

const CategoryGroup = ({ groupName, icon, isSelected, onSelect }) => (
  <li
    className={twJoin(
      'flex min-w-max cursor-pointer select-none items-center gap-x-2 rounded-3 py-2 transition-all hover:bg-neutral-high-200 dark:hover:bg-neutral-low-400',
      isSelected && 'bg-neutral-high-200 dark:bg-neutral-low-400'
    )}
    onFocus={onSelect}
    onMouseOver={onSelect}
  >
    <div className="rounded-2 bg-neutral-high-200 p-2 dark:bg-neutral-low-400">
      <Icon
        className="text-secondary-300 dark:text-secondary-300"
        name={icon}
        size="size3"
      />
    </div>
    <Text
      className="w-full text-start text-neutral-low-200 dark:text-neutral-high-300"
      size="size2"
    >
      {groupName}
    </Text>
    <Icon
      className="text-neutral-low-300 dark:text-neutral-high-300"
      name="arrow-forward"
    />
  </li>
);

CategoryGroup.propTypes = {
  groupName: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default CategoryGroup;
