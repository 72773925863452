import NextLink from 'next/link';
import PropTypes from 'prop-types';

import Button from 'shopper/components/Button';
import Text from 'shopper/components/Text';

import placeholder from 'lib/placeholder';

import LINKS from 'constants/links';

const Footer = ({ onClose }) => (
  <footer className="flex items-center justify-between bg-neutral-high-200 px-6 py-4 dark:bg-neutral-low-400">
    <div>
      <Text
        className="text-neutral-low-300 dark:text-neutral-high-300"
        size="size3"
        bold
      >
        {placeholder('titles.storesDropdown')}
      </Text>
      <Text
        className="text-neutral-low-300 dark:text-neutral-high-300"
        size="size3"
      >
        {placeholder('subtitles.storesDropdown')}
      </Text>
    </div>
    <NextLink href={LINKS.STORES} prefetch={false} passHref>
      <Button as="a" size="size3" type="primary" onClick={onClose}>
        {placeholder('actions.seeAllStores')}
      </Button>
    </NextLink>
  </footer>
);

Footer.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Footer;
