import PropTypes from 'prop-types';
import { createElement, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const HTML_MARKUP_OPTIONS = { div: 'div', a: 'a', button: 'button' };

const DropdownItem = forwardRef(
  (
    {
      ariaLabel,
      as = 'div',
      children,
      className,
      disabled = false,
      divider = false,
      ...rest
    },
    ref
  ) =>
    createElement(
      HTML_MARKUP_OPTIONS[as] || HTML_MARKUP_OPTIONS.div,
      {
        ref,
        'aria-label': ariaLabel,
        className: twMerge(
          'w-full flex select-none items-center whitespace-nowrap px-4 py-2 text-sm text-neutral-low-300 dark:bg-neutral-low-500 dark:text-neutral-high-300 lg:text-base',
          divider &&
            'border-t border-solid border-neutral-high-300 dark:border-neutral-low-300',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          !disabled &&
            'hover:bg-neutral-high-200 dark:hover:bg-neutral-low-400',
          className
        ),
        disabled,
        role: 'menuitem',
        ...rest,
      },
      children
    )
);

DropdownItem.displayName = 'DropdownItem';

DropdownItem.propTypes = {
  /**
   * Polymorphic changes the rendered html tag
   */
  as: PropTypes.oneOf(Object.values(HTML_MARKUP_OPTIONS)),
  /**
   * `aria-label` to be used
   */
  ariaLabel: PropTypes.string,
  /**
   * List text to be rendered as children
   */
  children: PropTypes.node.isRequired,
  /**
   * Renders component as disabled
   */
  disabled: PropTypes.bool,
  /**
   * Whether item uses a divider
   */
  divider: PropTypes.bool,
  /**
   * Callback function on click
   */
  onClick: PropTypes.func,
};

export default DropdownItem;
