import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

import useScrollPosition from 'hooks/useScrollPosition';

import { sendEvent } from 'lib/gtag';
import placeholder from 'lib/placeholder';

import CategoriesDropdown from './CategoriesDropdown';

const CategoriesDropdownButton = ({ gaEventCategory }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const categoriesButtonElem = useRef(null);

  const onCategoriesButtonClick = () => {
    sendEvent({ action: 'categories_click', category: gaEventCategory });
    setDropdownOpen((prev) => !prev);
  };

  const onCloseDropdown = useCallback(() => {
    setDropdownOpen(false);
    setActiveCategory(null);
  }, []);

  useScrollPosition(onCloseDropdown, { enabled: isDropdownOpen });

  return (
    <div
      className={twJoin('relative', isDropdownOpen && 'z-50')}
      onMouseLeave={() => setActiveCategory(null)}
    >
      <Button
        ref={categoriesButtonElem}
        className={twJoin('mr-4', isDropdownOpen && 'relative z-50')}
        data-test-selector="open-categories-dropdown"
        iconLeft={<Icon name="shopping-cart-filled" />}
        iconRight={<Icon name={isDropdownOpen ? 'arrow-up' : 'arrow-down'} />}
        size="size4"
        type={isDropdownOpen ? 'neutral-stroke' : 'neutral-ghost'}
        onClick={onCategoriesButtonClick}
      >
        {placeholder('actions.openCategories')}
      </Button>
      <CategoriesDropdown
        activeCategory={activeCategory}
        isOpen={isDropdownOpen}
        openerRef={categoriesButtonElem}
        setActiveCategory={setActiveCategory}
        onClose={onCloseDropdown}
      />
    </div>
  );
};

CategoriesDropdownButton.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
};

export default CategoriesDropdownButton;
