/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import NextLink from 'next/link';
import PropTypes from 'prop-types';

import Avatar from 'shopper/components/Avatar';
import Text from 'shopper/components/Text';

import { toStoreImageAltText } from 'lib/image';
import { toStoreOffersLink } from 'lib/links';

const Store = ({
  pageFrom,
  pageImage,
  pageParam,
  pageTitle,
  showStoreImage = false,
  onClick,
}) => (
  <NextLink href={toStoreOffersLink(pageParam)} prefetch={false} passHref>
    <a
      className="grid grid-cols-[24px,auto] grid-rows-2 items-center justify-start gap-x-2"
      data-test-selector="store-dropdown-item"
      onClick={onClick}
    >
      {showStoreImage && (
        <Avatar
          alt={toStoreImageAltText(pageTitle)}
          className="row-span-1"
          description={toStoreImageAltText(pageTitle)}
          size="size6"
          src={pageImage}
        />
      )}
      <Text
        className="col-start-2 text-neutral-low-300 dark:text-neutral-high-300"
        size="size3"
        bold
      >
        {pageTitle}
      </Text>
      <Text
        className="col-span-2 row-start-2 text-neutral-high-500 dark:text-neutral-high-400"
        size="size3"
      >
        {pageFrom}
      </Text>
    </a>
  </NextLink>
);

Store.propTypes = {
  showStoreImage: PropTypes.bool,
  pageFrom: PropTypes.string.isRequired,
  pageImage: PropTypes.string.isRequired,
  pageParam: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Store;
