import PropTypes from 'prop-types';

import { stripDuplicates } from 'lib/array';

import CategoryGroup from './CategoryGroup';

/**
 * Initially we store the icons for the categories as hardcoded, but in future
 * we need to change to receive it from API.
 */
const CATEGORY_GROUPS_ICONS = {
  'Smartphones, Eletrônicos e TV': 'phone-ios',
  'Informática e Games': 'games-filled',
  'Casa e Eletrodomésticos': 'kitchen-filled',
  'Moda e Acessórios': 'apparel',
  'Bebês e Crianças': 'baby',
  'Saúde e Beleza': 'heart-filled',
  'Esporte e Suplementos': 'fitness-center',
  'Supermercado e Delivery': 'shopping-cart-filled',
  'Livros, Ebooks e eReaders': 'book-filled',
  'Ferramentas e Jardim': 'florist',
  Diversos: 'shopping-bag',
  'Black Friday': 'percent',
};

const getCategoryGroupNamesAsGroupSort = (categories) =>
  stripDuplicates(categories?.map((category) => [category.groupName]).flat());

const CategoriesGroups = ({ activeCategory = null, categories, onSelect }) => {
  const groups = getCategoryGroupNamesAsGroupSort(categories);

  return (
    <div className="flex max-h-[calc(100vh_-_10rem)] min-w-80 flex-col overflow-y-auto p-4">
      {groups.map((groupName) => (
        <CategoryGroup
          key={groupName}
          groupName={groupName}
          icon={CATEGORY_GROUPS_ICONS[groupName]}
          isSelected={activeCategory === groupName}
          onSelect={() => {
            onSelect(groupName);
          }}
        />
      ))}
    </div>
  );
};

CategoriesGroups.propTypes = {
  activeCategory: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number,
      categoryName: PropTypes.string.isRequired,
      categoryUrl: PropTypes.string.isRequired,
      subcategories: PropTypes.array.isRequired,
    }).isRequired
  ),
  onSelect: PropTypes.func.isRequired,
};

export default CategoriesGroups;
