import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

import useScrollPosition from 'hooks/useScrollPosition';

import { sendEvent } from 'lib/gtag';
import placeholder from 'lib/placeholder';

import StoresDropdown from './StoresDropdown';

const StoresDropdownButton = ({ gaEventCategory }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const storesButtonRef = useRef(null);

  const onStoresButtonClick = () => {
    sendEvent({ action: 'stores_click', category: gaEventCategory });
    setDropdownOpen((prev) => !prev);
  };

  const onCloseDropdown = () => {
    setDropdownOpen(false);
  };

  useScrollPosition(onCloseDropdown, { enabled: isDropdownOpen });

  return (
    <div className={twJoin('relative', isDropdownOpen && 'z-50')}>
      <Button
        ref={storesButtonRef}
        className={twJoin('mr-4', isDropdownOpen && 'relative z-50')}
        data-test-selector="open-stores-dropdown"
        iconLeft={<Icon name="store" />}
        iconRight={<Icon name={isDropdownOpen ? 'arrow-up' : 'arrow-down'} />}
        size="size4"
        type={isDropdownOpen ? 'neutral-stroke' : 'neutral-ghost'}
        onClick={onStoresButtonClick}
      >
        {placeholder('actions.toggleStores')}
      </Button>
      <StoresDropdown
        isOpen={isDropdownOpen}
        openerRef={storesButtonRef}
        onClose={onCloseDropdown}
      />
    </div>
  );
};

StoresDropdownButton.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
};

export default StoresDropdownButton;
